import React from 'react'
import SEO from '../components/seo'

function NotFoundPage({ location }) {
  return (
    <>
      <SEO title="404: Not found" path={location.pathname} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </>
  )
}

export default NotFoundPage
